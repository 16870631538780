import React from "react"
import P from "../../core/P"

export const StepUp = ({ feature, size }) => {
  const contentData = (data, index) => {
    return (
      <div className=" mt-10 px-8 py-5 flex justify-center" key={Math.random()}>
        <div
          className={`${
            (index + 1) % 3 === 0
              ? "lg:mt-0"
              : (index + 1) % 3 === 2
              ? "lg:mt-16"
              : "lg:mt-32"
          } max-w-xs`}
          // data-scroll
          // data-scroll-speed={`${
          //   (index + 1) % 3 === 0 ? "1" : (index + 1) % 3 === 2 ? "2" : "3"
          // }`}
          data-aos="fade-up"
          data-aos-duration={`${
            (index + 1) % 3 === 0
              ? "400"
              : (index + 1) % 3 === 2
              ? "600"
              : "800"
          }`}
        >
          <img
            src={data?.icon?.mediaItemUrl}
            alt={data?.icon?.altText ? data?.icon?.altText : "Mobile View"}
            // title="Mobile View"
            loading="lazy"
            height={data?.icon?.mediaDetails?.height}
            width={data?.icon?.mediaDetails?.width}
            className={`object-fill w-72 ${data.title ? "pb-5" : " "}`}
          />
          <P
            title={data.title}
            className={`font-bold text-left text-shark-500 ${
              data.description ? "pb-5" : " "
            } `}
            noPad
          />
          <P
            title={data.description}
            className={`text-left text-shark-400`}
            noPad
          />
        </div>
      </div>
    )
  }
  return (
    <div className="bg-transperent mt-1 step-up-feature-bg ">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <div className={`lg:grid lg:grid-cols-${size} lg:gap-6`}>
          {feature.length > 0 &&
            feature.map((data, index) => (
              <div key={Math.random()}>{contentData(data, index)}</div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default StepUp
